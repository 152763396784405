import Routes from "./routes";
import 'antd/dist/antd.css'
import './App.css';

function App() {
  return (
    <div>
      <Routes />
    </div>
  );
}

export default App;
